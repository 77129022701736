import React from 'react';
import mainImg from "../../images/Foto_Oben_mobile.jpg";
import './mobile-main-teaser.css';
import ContactButton from "./mobile-button";

const MainTeaser = () => {
    return (
        <div className="m-main-teaser">
            <img src={mainImg} alt="Eltern, Kinder, Liebe" width="100%" className="m-main-img" />
            <div className="m-main-text">
                <p>Eltern.</p>
                <p>Kinder.</p>
                <p>Liebe.</p>
                <div style={{top:"30px", position: "relative"}}><ContactButton/></div>
                
            </div>
        </div>
    )

}

export default MainTeaser;