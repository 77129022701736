import React from 'react';
import './main_header.css';
import sfs_img from './../images/Foto_Oben.jpg';
import ContactButton from './button';

const MainHeader = (props) => {
    return (
    <div className="mh-wrapper">
        <img className="mh-img" src={sfs_img} alt="Eltern, Kinder, Liebe"/>
        <div className="mh-text">
            <div className="mh-ekl">
                <p className="p-ekl p-e">Eltern.</p>
                <p className="p-ekl p-k">Kinder.</p>
                <p className="p-ekl p-l">Liebe.</p>
            </div>
            <div className="mh-subtitle">
                Coaching für Mütter und Väter
            </div>
            <div className="mh-btn">
               <ContactButton big color="white" target="footer" onClick={props.onClickButton} />
            </div>
            
        </div>

    </div>
    )
}

export default MainHeader;