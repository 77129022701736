import React from 'react';
import './image_with_text.css';

const ImageWithText = (props) => {
    let side = "iwt-text " + (props.right ? 'iwt-right' : 'iwt-left');

    return <div id={props.id} className="iwt-container">
        <img src={props.backgroundImage} className="iwt-img" />
        <div className={side}>
            {props.children}
        </div>
    </div>
}

export default ImageWithText;