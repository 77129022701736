import React, {useRef, useState} from 'react';
import './mobile-image-teaser.css';
import ContactButton from './mobile-button';
import useOnScreen from '../../hooks/useOnScreen';

const ImageTeaser = ({img, children, hiddenText, id}) => {
    const [showText, setShowText] = useState(true);

    const handleClick = () => {
        setShowText(!showText);
    }

    const ref = useRef();
    const isVisible = useOnScreen(ref);

    return (
        <div ref={ref} id={id} className="m-img-teaser" onClick={handleClick}>
            <img src={img} width="100%" className={(showText && isVisible) ? "transparent" : ""} />
            <div className="m-img-teaser-content">
                <div className={"m-img-teaser-text" + (showText && isVisible ? " transparent" : "")}>
                    {children}
                </div>
                <ContactButton style={{bottom: "10px", position: "relative"}} />
            </div>
            {hiddenText ? (<div className="m-img-teaser-hidden-text">
                <p>1. Wir finden heraus was für Veränderungen du dir wünschst, was du konkret erreichen möchtest.</p>
                <p>2. Mit Hilfe meiner Fragen erkennst du, warum es bei dir so ist wie es ist.</p>
                <p>3. Ich gebe dir Schlüssel an die Hand, mit denen du zukünftig bekommst was du willst.</p>
                <p>4. Du erfährst, wie du gewonnene Erkenntnisse dauerhaft in dein Leben integrierst.</p>
            </div>) : null}
        </div>
    )
}

export default ImageTeaser;