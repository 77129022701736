import React, {useState} from 'react';
import './mobile-text-frame.css';

const TextFrame = (props) => {
    const [readMore, setReadMore] = useState(false);

    const children = React.Children.toArray(props.children);


    return (
        <div className="mtf-container" id={props.id}>
            <p className="mtf-title">{props.title}</p>
            {children.slice(0,3)}
            <div className={"mtf-rest" + (readMore ? " expand" : "")}>
            <div className={"read-on" + (readMore ? " mtf-hidden" : "")} onClick={() => setReadMore(true)}>mehr lesen...</div>
                {children.slice(3)}
            </div>
            <div className="read-less" onClick={() => setReadMore(false)} hidden={!readMore}>weniger lesen...</div>
            
        </div>
    )
}

export default TextFrame;