import React from 'react';
import './footer_item.css';

const FooterItem = (props) => {

    let children = React.Children.map(props.children, child => {
        return React.cloneElement(child, {className: (child.props.className ? child.props.className : "") + " ftr-item-li-2"});
    })

    return (
        <div className="ftr-item-wrapper">
            <p className={"ftr-item-heading"} onClick={props.onClick}>{props.title || <br/>}</p>
            {children}
        </div>
    )
}

export default FooterItem