import ContactButton from './mobile-button';
import './mobile-contact-teaser.css';
import contact_img from '../../images/UeberMich_2.jpg';

const ContactTeaser = () => {
    return (
        <div id="footer" className="m-contact-teaser">
            <img src={contact_img} width="100%" alt="Kontakt"/>
            <div className="m-contact-teaser-text">
                <p className="m-contact-teaser-heading">Kontakt</p>
                <p>Jeanine Klüver</p>
                <a href="mailto:mail@kluevercoaching.com">mail@kluevercoaching.com</a>
                <a href="tel:+4917663720834">0176/63720834</a>
            </div>
        </div>
    )
}

export default ContactTeaser;