import React, {useState} from 'react';
import './mobile-header.css';
import Navigation from './mobile-navigation';

const Header = ({onClick}) => {
    const [showNav, setShowNav] = useState(false);

    const handleClick = () => {
        let toggle = !showNav;
        setShowNav(toggle);
    }

    let cn = "m-nav-list " + (showNav ? "m-nav-list-active" : "");

    return (
        <div>
            <div className="m-header">
                <div className="m-header-text">
                    <p>Jeanine Klüver.</p>
                    <p>Coaching.</p>
                </div>
                <Navigation onClick={handleClick} showNav={showNav} />
            </div>
            <div className={cn}>
                <div onClick={()=>onClick("vision")}>Meine Vision</div>
                <div onClick={()=>onClick("angebot")}>Mein Angebot</div>
                <div onClick={()=>onClick("m-sfs")}>Schritt für Schritt</div>
                <div onClick={()=>onClick("uebermich")}>Über mich</div>
                <div onClick={()=>onClick("footer")}>Jetzt buchen</div>
            </div>
        </div>
        
        
    )
}

export default Header;