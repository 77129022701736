import React from 'react';
import './mobile-button.css';

const ContactButton = ({style}) => {

    const smoothScroll = () => {
        let y = document.getElementById("footer").offsetTop;
        window.scrollTo({
          top: y,
          left: 0,
          behavior: 'smooth'
        });
    }

    return (
        <div onClick={smoothScroll} style={style}>
            <p className="m-btn-text">Kostenloses Erstgespräch</p>
        </div>)
}

export default ContactButton;