import React from 'react';
import Header from '../components/mobile-components/mobile-header';
import MainTeaser from '../components/mobile-components/mobile-main-teaser';
import TextFrame from '../components/mobile-components/mobile-text-frame';
import ImageTeaser from './mobile-components/mobile-image-teaser';
import ImageTeaser2 from './mobile-components/mobile-image-teaser-2';
import ContactTeaser from './mobile-components/mobile-contact-teaser';
import ContactForm from './mobile-components/mobile-form';
import Footer from './mobile-components/mobile-footer';

import vision_image from '../images/MeineVision.jpeg';
import sfs_image from '../images/SchrittFuerSchritt_mobil.jpeg';

function MobileApp() {

    const smoothScroll = (target) => {
        let y = document.getElementById(target).offsetTop;
        window.scrollTo({
          top: y,
          left: 0,
          behavior: 'smooth'
        });
    }

    return(
        <div className="m-mainframe">
            <Header onClick={smoothScroll} />
            <MainTeaser/>
            <TextFrame title="Meine Vision." id="vision">
                <p>…sind glückliche Eltern und glückliche Kinder. Was ist deine?</p>
                <p>Du bist frisch getrennt und weißt nicht, wie du das mit der Mutter, dem Vater deiner Kinder hinbekommen sollst? Es gibt bei jeder Gelegenheit Streit und dir geht es immer häufiger schlecht vor den Treffen, bei denen es um eure Kinder, deren Erziehung und vielleicht auch um Geld geht? Du lebst schon lange getrennt von deiner Ex-Partner:in und es funktioniert einfach nicht zwischen euch? Immer wieder kommen die alten Vorwürfe hoch und es belastet dich weiter und weiter? Oder bist du in Partnerschaft und hast das Gefühl, es geht nur noch um die Kinder, ihr verliert euch mehr und mehr? Ich kenne all das sehr gut aus eigener Erfahrung.</p>
                <p>Eltern und Kinder stehen bei meinem Coaching im Mittelpunkt. Auch dein Kind, deine Kinder, bekommen mit, dass es ständig Spannungen gibt. Wenn du schon mal zwischen zwei deiner Freund:innen standest, die sich streiten, weißt du annähernd, wie es sich für deine Kinder anfühlt dazwischen zu stehen.</p>
                <p>In welcher Situation du auch gerade feststeckst, ich wünsche dir, dass du dein Leben wieder ohne belastende Vorwürfe und Schuldgefühle frei gestalten kannst. Das bedeutet: du und der Vater bzw. die Mutter deiner Kinder könnt wieder in Frieden miteinander kommunizieren. Ein großer Gewinn für dich und auch für eure Kinder. Deine Kinder müssen sich für keine Seite mehr entscheiden, sondern können bei allen Eltern frei und unbefangen sein. Deine Kinder fühlen sich sicher und geborgen. Du sorgst dafür, dass es bei dir zu Hause wieder fröhlicher und unbeschwerter zugeht.</p>
                <p>Hast du Lust darauf? Dann melde dich bei mir und finde heraus, ob mein Coaching ein Weg für dich ist.</p>
            </TextFrame>
            <ImageTeaser2 img={vision_image} hiddenText>
                <p>Coaching für</p>
                <p>Mütter und Väter</p>
            </ImageTeaser2>
            <TextFrame title="Mein Angebot für dich." id="angebot">
                <p>In meinem Coaching - per Video oder auf Wunsch telefonisch - geht es darum, wieder das Steuer in die Hand zu nehmen und nicht mehr auf der Rückbank zu sitzen, nicht in der Lage, die Richtung im eigenen Leben zu bestimmen. Dafür ist es hilfreich zu erkennen, wann und wieso du das Steuer losgelassen hast. Das ist einer der ersten Schritte, um den Raum für Veränderung zu öffnen.</p>
                <p>Mit meiner Methode ermögliche ich dir eine nachhaltige Veränderung in deinem Leben. Dabei durchlaufen wir keinen jahrelangen Prozess, sondern untersuchen gemeinsam was die Ursachen für deine Sorgen und Gefühle sind. Du findest heraus, wie du die gewünschte Veränderung herbeiführst und sie dauerhaft erhältst. Dabei profitierst du von meiner Erfahrung und meinem Wissen als Coach und bekommst die Möglichkeit, völlig neue Sichtweisen kennenzulernen und Qualitäten für dein Leben bewusst zu wählen.</p>
                <p hidden></p>
                <p>Ob getrennt, in Partnerschaft lebend, gemeinsam- oder alleinerziehend: ein glückliches, unbeschwertes Leben ist möglich. Für dich, deine Kinder, deine Familie und mit deinen Freund:innen.</p>
                <p>Um das zu bekommen, brauchst du nur umzusetzen, was wir in den Sitzungen besprechen. Die Lösung liegt meistens viel näher als wir denken. Hast du schon Erfahrungen mit Coaching und wünschst dir eine Auffrischung oder es tun sich neue Fragen auf? Auch hierbei stehe ich dir gern zur Verfügung.</p>         
            </TextFrame>
            <ImageTeaser img={sfs_image} hiddenText id="m-sfs">
                <p>Schritt für Schritt</p>
            </ImageTeaser>
            <TextFrame title="Über mich." id="uebermich">
                <p>Ich bin 1965 geboren und Mutter von zwei erwachsenen Kindern. Seit meiner Jugend beschäftige ich mich mit persönlicher Weiterentwicklung, mit Kommunikation und der Frage, wie Leben einfacher und schöner sein kann. </p>
                <p>Seit 2001 lebe ich getrennt von dem Vater meiner Kinder. Gemeinsam haben wir seitdem ein Familienleben erschaffen, welches für uns und unsere Kinder und auch für neue Partner:innen nicht nur funktioniert, sondern von Liebe und Respekt geprägt ist. </p>
                <p>Das war nicht immer so. Mein Ex-Mann und ich hatten immer wieder Stress miteinander, waren genervt voneinander, haben uns auch vor den Kindern gestritten.</p>
                <p>Mein Weg war also etwas länger und ganz ehrlich, häufig ganz schön anstrengend. Er war länger als er hätte sein müssen, das weiß ich heute. Das ist der Grund, wieso ich dir mein Coaching anbiete: damit du jetzt aussteigen kannst. </p>
                <p>Während meines ersten philosophischen Coachings habe ich erkannt, dass ich meinen Ex-Mann oft falsch verstanden hatte und wir die Konflikte aus der Vergangenheit immer weitergeführt haben. Nach dieser Erkenntnis begann ich direkt mit meiner mehrjährigen Ausbildung zum Coach. </p>
                <p>Meine Ausbildung bei den Coaches und Trainer:innen Thomas Georgi, Patrizia Voigtländer und Stefan Grosalski war ein befreiender und spannender Weg auf dem viele kleine Wunder und große Veränderungen auf mich gewartet haben. </p>
                <p>Schritt für Schritt habe ich viel über mich gelernt. Jetzt im Frieden mit meinem Ex-Mann und auch mit meinen Eltern und Geschwistern zu sein bedeutet eine ganz andere, angenehme Lebensqualität für mich.</p>
                <p>Aus meiner eigenen Erfahrung und auch der meiner Kunden sowie vieler anderer Menschen in meinem Umfeld weiß ich: Alles, was es dafür braucht, ist deine Entscheidung loszugehen und etwas zu ändern. Ich bin Coach aus Leidenschaft. Ich biete dir die Möglichkeit zu erfahren, dass Leben leichter geht und ohne Streit und Stress möglich ist. Ich wünsche mir für dich, dass du diesen Weg wählst und bekommst, was du wirklich möchtest.</p>
            </TextFrame>
            <ContactTeaser />
            <ContactForm />
            <Footer id="footer" />
        </div>
    )
}

export default MobileApp;