import './mobile-footer.css';
import datenschutz from '../../texts/Datenschutzerklaerung_Blogs_Homepages_ohne_Bestellfunktion-nicht_Shops-2021.pdf';
import { useState } from 'react';

const Footer = () => {
    const [showImpressum, setShowImpressum] = useState(false);

    async function handleClick (){
        const tmp = showImpressum;
        setShowImpressum(!tmp);
        if(tmp){
            return;
        }
        await new Promise(r => setTimeout(r, 333));
        window.scrollBy({
            top: 600,
            behavior: 'smooth'
        })
    }

    return (
        <div>
            <div className="m-footer">
                <div onClick={handleClick}>Impressum</div>
                <a href={datenschutz} target="_blank" className="m-datenschutz">Datenschutz</a>
            </div>
            <div className={"m-impressum" + (showImpressum ? " m-impressum-visible" : "")}>
                <p>Impressum</p>
                    <hr/>
                    <p>Jeanine Klüver<br/>
                        Getekamp 15<br/>
                        28205 Bremen<br/>
                        Deutschland
                    </p>
                    <p>
                        Tel.: 0176 63720834<br/>
                        E-Mail: mail@kluevercoaching.com 
                    </p>
                    <p>Plattform der EU-Kommission zur Online-Streitbeilegung: https://ec.europa.eu/odr</p>
                    <p>Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle weder verpflichtet noch bereit.</p>
                    <p>Webdesign: Nick Buljubašic<br/>
                    Webentwicklung: Fabian Herling<br/>
                    Porträtfotos: Linus Klose Photography</p>
            </div>
        </div>

    )
}

export default Footer;