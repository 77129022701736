import React from 'react';
import './button.css';

const ContactButton = ({target, color, big, onClick}) => {
    let cn = "contact-button " + (color ? color+" " : "") + (big ? "big" : "");

    return (
        <div href={"#"+target} className={cn} onClick={() => onClick(target)}>
            <p className="button-text">Kostenloses Erstgespräch</p>
        </div>)
}

export default ContactButton;