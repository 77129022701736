import React, {useRef, useState} from 'react';
import './mobile-image-teaser.css';
import ContactButton from './mobile-button';
import useOnScreen from '../../hooks/useOnScreen';

const ImageTeaser = ({img, children, hiddenText, id}) => {
    const [showText, setShowText] = useState(true);

    const handleClick = () => {
        setShowText(!showText);
    }

    const ref = useRef();
    const isVisible = useOnScreen(ref);

    return (
        <div ref={ref} id={id} className="m-img-teaser" onClick={handleClick}>
            <img src={img} width="100%" className={(showText && isVisible) ? "transparent" : ""} />
            <div className="m-img-teaser-content">
                <div className={"m-img-teaser-text-2" + ((showText && isVisible) ? " transparent" : "")}>
                    {children}
                </div>
                <ContactButton style={{bottom: "5px", position: "relative"}} />
            </div>
            {hiddenText ? (<div className="m-img-teaser-hidden-text v2">
                <p>Du kannst dir vielleicht gerade nicht vorstellen, wie es besser werden kann, denn Streit und unangenehme Situationen gehören im Moment einfach zu deinem Leben. Die gute Botschaft ist: du brauchst noch nicht mal diese Vorstellungskraft. Folge deinem Wunsch, etwas zu verändern und melde dich bei mir.</p>
            </div>) : null}
        </div>
    )
}

export default ImageTeaser;