import React, {useState, useEffect} from 'react';
import './App.css';
import vision_img from './images/MeineVision.jpeg';
import sfs_img from './images/SchrittFuerSchritt.jpeg';
import ueber_mich from './images/UeberMich_2.jpg';
import ContactButton from './components/button';
import ImageWithText from './components/image_with_text';
import ContactForm from './components/contact_form';
import FooterItem from './components/footer_item';
import Impressum from './components/impressum';
import MainHeader from './components/main_header';
import MobileApp from './components/mobile-app';
import datenschutz from './texts/Datenschutzerklaerung_Blogs_Homepages_ohne_Bestellfunktion-nicht_Shops-2021.pdf';

import smoothscroll from 'smoothscroll-polyfill';
import Dummy from './components/dummy';

const initial_color = [206,218,204];
const final_color = [240,252,237];
const color_offsets = [
  final_color[0] - initial_color[0],
  final_color[1] - initial_color[1],
  final_color[2] - initial_color[2]
];

function App() {
  smoothscroll.polyfill();

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [color, setColor] = useState(initial_color);
  const [impressum, setImpressum] = useState(false);
  
  const handleScroll = () => {
    const scrollY = window.scrollY;
    let total_height = document.documentElement.scrollHeight;
    let scroll_percentage = scrollY/total_height;
    if (scroll_percentage < 0.3){
      return;
    }
    scroll_percentage = Math.min((scroll_percentage - 0.3) * 10,1);
    let c = [
      color[0] + color_offsets[0] * scroll_percentage,
      color[1] + color_offsets[1] * scroll_percentage,
      color[2] + color_offsets[2] * scroll_percentage,
    ];
    setColor(c);
  }

  useEffect (() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, []) 

  const smoothScroll = (target) => {
    let y = document.getElementById(target).offsetTop;
    window.scrollTo({
      top: y,
      left: 0,
      behavior: 'smooth'
    });
  }

  const handleResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }

  if(width < 768 || width < height){
    return <MobileApp/>
  }

  return (
    <div className="App" style={{background: `rgb(${color[0]},${color[1]},${color[2]})`}}>
      <div className="header">
        <div className="title">
          <p>Jeanine Klüver. Coaching.</p>
        </div>
        <div className="navigation">
          <div className="navbar">
            <div className="nav-item active">Home</div>
            <div className="nav-item" onClick={() => smoothScroll("meine_vision")}>Meine Vision</div>
            <div className="nav-item" onClick={() => smoothScroll("mein_angebot")}>Mein Angebot</div>
            <div className="nav-item" onClick={() => smoothScroll("ueber_mich")}>Über mich</div>
            <div className="nav-item" onClick={() => smoothScroll("footer")}>Jetzt buchen</div>
          </div>
        </div>
      </div>
      <div className="content">
        <MainHeader onClickButton={smoothScroll}/>
        <div className="content-frame-header" id="meine_vision">Meine Vision.</div>
        <div className="content-frame">
          
          <div className="content-frame-text content-frame-text-left">
            <p style={{marginTop: "0px"}}>…sind glückliche Eltern und glückliche Kinder. Was ist deine?</p>
            <p>Du bist frisch getrennt und weißt nicht, wie du das mit der Mutter, dem Vater deiner Kinder hinbekommen sollst? Es gibt bei jeder Gelegenheit Streit und dir geht es immer häufiger schlecht vor den Treffen, bei denen es um eure Kinder, deren Erziehung und vielleicht auch um Geld geht? Du lebst schon lange getrennt von deiner Ex-Partner:in und es funktioniert einfach nicht zwischen euch? Immer wieder kommen die alten Vorwürfe hoch und es belastet dich weiter und weiter? Oder bist du in Partnerschaft und hast das Gefühl, es geht nur noch um die Kinder, ihr verliert euch mehr und mehr? Ich kenne all das sehr gut aus eigener Erfahrung.</p>
            <p>Eltern und Kinder stehen bei meinem Coaching im Mittelpunkt. Auch dein Kind, deine Kinder, bekommen mit, dass es ständig Spannungen gibt. Wenn du schon mal zwischen zwei deiner Freund:innen standest, die sich streiten, weißt du annähernd, wie es sich für deine Kinder anfühlt dazwischen zu stehen.</p>
            <p>In welcher Situation du auch gerade feststeckst, ich wünsche dir, dass du dein Leben wieder ohne belastende Vorwürfe und Schuldgefühle frei gestalten kannst. Das bedeutet: du und der Vater bzw. die Mutter deiner Kinder könnt wieder in Frieden miteinander kommunizieren. Ein großer Gewinn für dich und auch für eure Kinder. Deine Kinder müssen sich für keine Seite mehr entscheiden, sondern können bei allen Eltern frei und unbefangen sein. Deine Kinder fühlen sich sicher und geborgen. Du sorgst dafür, dass es bei dir zu Hause wieder fröhlicher und unbeschwerter zugeht.</p>
            <p>Hast du Lust darauf? Dann melde dich bei mir und finde heraus, ob mein Coaching ein Weg für dich ist.</p>
          </div>
          <div className="content-frame-img"><img src={vision_img} alt="Meine Vision" className="vision_img"/></div>
        </div>
        <div className="content-frame-header cfh-right" id="mein_angebot">Mein Angebot für dich.</div>
        <div className="content-frame">
          <div className="content-frame-img">
            <div className="img-container">
              <div id="sfs">
                <img src={sfs_img} className="sfs-img"/>
              </div>
              <div id="sfs-text">
                <p className="paragraph-header">Schritt für Schritt.</p>
                <p>1.	Wir finden heraus was für Veränderungen du dir wünschst, was du konkret erreichen möchtest.</p>
                <p>2.	Mit Hilfe meiner Fragen erkennst du, warum es bei dir so ist wie es ist.</p>
                <p>3.	Ich gebe dir Schlüssel an die Hand, mit denen du zukünftig bekommst was du willst.</p>
                <p>4. Du erfährst, wie du gewonnene Erkenntnisse dauerhaft in dein Leben integrierst.</p>
              </div>
            </div>
            
          </div>
          <div className="content-frame-text">
            <p style={{marginTop: "0px"}}>In meinem Coaching - per Video oder auf Wunsch telefonisch - geht es darum, wieder das Steuer in die Hand zu nehmen und nicht mehr auf der Rückbank zu sitzen, nicht in der Lage, die Richtung im eigenen Leben zu bestimmen. Dafür ist es hilfreich zu erkennen, wann und wieso du das Steuer losgelassen hast. Das ist einer der ersten Schritte, um den Raum für Veränderung zu öffnen.</p>
            <p>Mit meiner Methode ermögliche ich dir eine nachhaltige Veränderung in deinem Leben. Dabei durchlaufen wir keinen jahrelangen Prozess, sondern untersuchen gemeinsam was die Ursachen für deine Sorgen und Gefühle sind. Du findest heraus, wie du die gewünschte Veränderung herbeiführst und sie dauerhaft erhältst. Dabei profitierst du von meiner Erfahrung und meinem Wissen als Coach und bekommst die Möglichkeit, völlig neue Sichtweisen kennenzulernen und Qualitäten für dein Leben bewusst zu wählen.</p>
            <p>Ob getrennt, in Partnerschaft lebend, gemeinsam- oder alleinerziehend: ein glückliches, unbeschwertes Leben ist möglich. Für dich, deine Kinder, deine Familie und mit deinen Freund:innen.</p>
            <p>Um das zu bekommen, brauchst du nur umzusetzen, was wir in den Sitzungen besprechen. Die Lösung liegt meistens viel näher als wir denken. Hast du schon Erfahrungen mit Coaching und wünschst dir eine Auffrischung oder es tun sich neue Fragen auf? Auch hierbei stehe ich dir gern zur Verfügung.</p>
            <div className="centered"><ContactButton target="footer" onClick={smoothScroll}/></div>
            
          </div>         
        </div>
        <ImageWithText id="ueber_mich" backgroundImage={ueber_mich} >
          <p className="paragraph-header">Über mich.</p>
          <p>Ich bin 1965 geboren und Mutter von zwei erwachsenen Kindern. Seit meiner Jugend beschäftige ich mich mit persönlicher Weiterentwicklung, mit Kommunikation und der Frage, wie Leben einfacher und schöner sein kann. </p>
          <p>Seit 2001 lebe ich getrennt von dem Vater meiner Kinder. Gemeinsam haben wir seitdem ein Familienleben erschaffen, welches für uns und unsere Kinder und auch für neue Partner:innen nicht nur funktioniert, sondern von Liebe und Respekt geprägt ist.<br/> 
          Das war nicht immer so. Mein Ex-Mann und ich hatten immer wieder Stress miteinander, waren genervt voneinander, haben uns auch vor den Kindern gestritten. Mein Weg war also etwas länger und ganz ehrlich, häufig ganz schön anstrengend. Er war länger als er hätte sein müssen, das weiß ich heute. Das ist der Grund, wieso ich dir mein Coaching anbiete: damit du jetzt aussteigen kannst. </p>
          <p>Während meines ersten philosophischen Coachings habe ich erkannt, dass ich meinen Ex-Mann oft falsch verstanden hatte und wir die Konflikte aus der Vergangenheit immer weitergeführt haben. Nach dieser Erkenntnis begann ich direkt mit meiner mehrjährigen Ausbildung zum Coach.<br />
          Meine Ausbildung bei den Coaches und Trainer:innen Thomas Georgi, Patrizia Voigtländer und Stefan Grosalski war ein befreiender und spannender Weg auf dem viele kleine Wunder und große Veränderungen auf mich gewartet haben. Schritt für Schritt habe ich viel über mich gelernt. Jetzt im Frieden mit meinem Ex-Mann und auch mit meinen Eltern und Geschwistern zu sein bedeutet eine ganz andere, angenehme Lebensqualität für mich.</p>
          <p>Aus meiner eigenen Erfahrung und auch der meiner Kunden sowie vieler anderer Menschen in meinem Umfeld weiß ich: Alles, was es dafür braucht, ist deine Entscheidung loszugehen und etwas zu ändern. Ich bin Coach aus Leidenschaft. Ich biete dir die Möglichkeit zu erfahren, dass Leben leichter geht und ohne Streit und Stress möglich ist. Ich wünsche mir für dich, dass du diesen Weg wählst und bekommst, was du wirklich möchtest.</p>
          <ContactButton color="white" target="footer" onClick={smoothScroll} />
        </ImageWithText>
        

      </div>
      
      <div className="footer" id="footer">
        <div style={{width: "50%", position: "relative", top: "4%"}}>
          <ContactForm />
        </div>
        <div className="footer-frame">
          <FooterItem title="Kontakt">
            <p>Jeanine Klüver</p>
            <a href="mailto:mail@kluevercoaching.com">mail@kluevercoaching.com</a>
            <p>0176/63720834</p>
          </FooterItem>
          <FooterItem >
          <p onClick={() => setImpressum(true)}>Impressum</p>
          <a href={datenschutz} target="_blank" className="datenschutz">Datenschutz</a>
          </FooterItem>
        </div>
        
      </div>

      <Impressum show={impressum} onClick={() => setImpressum(false)} />
      <Dummy />

    </div>
  );
}

export default App;
