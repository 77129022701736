import './mobile-form.css';
import emailjs from 'emailjs-com';
import React, {useRef, useState} from 'react';

const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

const ContactForm = () => {
    const [formValues, setFormValues] = useState({
        vorname: "",
        nachname: "",
        email: "",
        message: "",
        tel: "",
    });
    const [errors, setErrors] = useState();
    const [success, setSuccess] = useState(false);

    const handleChange = (e) => {
        setFormValues({
            ...formValues,
            [e.target.name]: e.target.value
        });
        setErrors(null);
        setSuccess(false);
    }

    const handleSubmit = (e) => {
        setErrors(null);
        e.preventDefault();
        if(formValues.vorname.trim() == "") {
            setErrors("Bitte geben sie einen Vornamen ein");
            document.getElementById("vorname").focus();
            return;
        }
        if(formValues.nachname.trim() == "") {
            setErrors("Bitte geben sie einen Nachnamen ein");
            document.getElementById("nachname").focus();
            return;
        }
        if(formValues.tel.trim() == "") {
            setErrors("Bitte geben sie eine Telefonnummer ein ein");
            document.getElementById("tel").focus();
            return;
        } 
        if(formValues.message.trim() == "") {
            setErrors("Bitte geben sie eine Nachricht ein");
            document.getElementById("message").focus();
            return;
        }
        if(!formValues.email.match(mailformat)){
            setErrors("Bitte geben sie eine gültige E-Mail-Adresse an")
            document.getElementById("email").focus();
            return;
        }
        setErrors(null);
        setSuccess(true);
        
        emailjs.sendForm(process.env.REACT_APP_EMJS_SERVICE_ID, process.env.REACT_APP_EMJS_TEMPLATE_ID, e.target, process.env.REACT_APP_EMJSU)
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            alert("Da ist leider etwas schief gegangen. Bitte versuchen sie es noch einmal, oder kontaktieren sie mich direkt per Email");
        });
        setFormValues(
            {
                vorname: "",
                nachname: "",
                email: "",
                message: "",
                tel: ""
            }
        );
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="m-contact-form-container">
                <div className="m-contact-form-wrapper">
                    <div className="m-contact-form-heading">Hallo.</div>
                    <div className="m-form-row">
                        <input type="text" id="vorname" name="vorname" value={formValues.vorname} onChange={handleChange} className="m-contact-form-inp m-contact-form-name" placeholder="Vorname"></input>
                        <input type="text" id="nachname" name="nachname" value={formValues.nachname} onChange={handleChange} className="m-contact-form-inp m-contact-form-name" placeholder="Nachname"></input>
                    </div>
                    <div className="m-form-row">
                        <input type="email" id="email" name="email" value={formValues.email} onChange={handleChange} className="m-contact-form-inp m-contact-form-email" placeholder="E-Mail-Adresse"></input>
                        <input type="tel" value={formValues.tel} name="tel" id="tel" onChange={handleChange} placeholder="Telefonnummer" className="m-contact-form-inp m-contact-form-name"></input>
                    </div>
                    <textarea name="message" id="message" value={formValues.message} onChange={handleChange} className="m-contact-form-inp m-contact-form-message" placeholder="Text"></textarea>   
                    <div className="m-form-row">
                        <button type="submit" className="m-form-btn">Absenden</button>
                        {success && <div className="m-contact-form-greeting"><strong>Ich freu mich auf dich.</strong></div> }                 
                    </div>
                    
                </div>
                <div className="m-feedback-wrapper">
                    <div className={"m-form-feedback m-form-errors " + (errors ? " m-form-feedback-visible" : "")}>{errors}</div>
                </div>   
            </div>
        </form>
    )
}

export default ContactForm;