import React, { useState, useEffect } from 'react';

function useOnScreen(ref){
    const [onScreen, setOnScreen] = useState(false);

    let options = {
        rootMargin: '0px',
        threshold: 0.8,
    }
    const observer = new IntersectionObserver(
        ([entry]) => setOnScreen(entry.isIntersecting), options
    );

    useEffect(() => {
        observer.observe(ref.current);

        return () => observer.disconnect();
    },[]);

    return onScreen;
}

export default useOnScreen;