import React from 'react';
import './impressum.css'
import './impressum2.css';

const Impressum = ({show, onClick}) => {
    if (!show) {
        return null;
    }

    return (
        <div className="overlay2" onClick={onClick}>
            <div className="impressum2" onClick={(e) => e.stopPropagation()}>
                <div className="impressum-text2">
                    <p className="impressum-header2">Impressum</p>
                    <hr/>
                    <p>Jeanine Klüver<br/>
                        Getekamp 15<br/>
                        28205 Bremen<br/>
                        Deutschland
                    </p>
                    <p>
                        Tel.: 0176 63720834<br/>
                        E-Mail: mail@kluevercoaching.com 
                    </p>
                    <p>Plattform der EU-Kommission zur Online-Streitbeilegung: https://ec.europa.eu/odr</p>
                    <p>Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle weder verpflichtet noch bereit.</p>

                    <p>Webdesign: Nick Buljubašic<br/>
                    Webentwicklung: Fabian Herling<br/>
                    Porträtfotos: Linus Klose Photography</p>
                </div>
            </div>
        </div>
        
    )
}

export default Impressum;