import React from 'react';
import './mobile-navigation.css';

const Navigation = ({onClick, showNav}) => {

    return (
        <div className="m-nav-wrapper">
            <div className={"m-nav cross" + (showNav ? " active" : "")} onClick={onClick} >
                <hr style={{position: "absolute", transform: "rotate(45deg)"}}/>
                <hr style={{position: "absolute", transform: "rotate(315deg)"}}/>
            </div>
            <div className={"m-nav stripes" + (showNav ? "" : " active")} onClick={onClick} >
                <hr/>
                <hr/>
                <hr/>
            </div>
        </div>
    )
}

export default Navigation;